import Worksite from '~~/app/worksite/models/Worksite';
import ModelFileFolder from '~~/app/common/models/ModelFileFolder';

export const useUploadDropZoneStore = defineStore('UploadDropZoneStore', () => {
    const worksite: Ref<Worksite | null> = ref(null);
    const enabled: Ref<boolean> = ref(true);
    const modelFileFolder: Ref<ModelFileFolder | null> = ref(null);

    const setWorksite = (model: Worksite | null) => {
        worksite.value = model;
    };
    const setModelFileFolder = (model: ModelFileFolder | null) => {
        modelFileFolder.value = model;
    };

    const enable = () => (enabled.value = true);
    const disable = () => (enabled.value = false);

    return {
        enabled,
        enable,
        disable,
        worksite,
        modelFileFolder,
        setWorksite,
        setModelFileFolder,
    };
});
